//**********************************************************
// Quiz Block
//**********************************************************

@import '../../../scss/import';

.quiz-block {
	max-width: 952px;
	width: 100%;
	margin: 0 auto;
	text-align: center;

	// ##################################################

	&__header {
		display: grid;
		grid-gap: 70px;
		margin-bottom: 60px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			grid-gap: 40px;
			margin-bottom: 40px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-gap: 30px;
			margin-bottom: 30px;
		}
	}

	// ##################################################

	&__progress-bar {
		position: relative;
		max-width: 700px;
		width: 100%;
		height: 42px;
		margin: 0 auto;
		background-color: $cgp_white;
		border-radius: 25px;
		border: 3px solid $cgp_font_color;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 35px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 26px;
			border-width: 2px;
		}

		// ##################################################

		&-inner {
			position: absolute;
			height: 100%;
			border-radius: inherit;
			left: 0;
			top: 0;
			background-color: $cgp_font_color;
			pointer-events: none;
			transition: width 0.3s ease;

			// ##################################################
			// Overlay

			&::before {
				@extend %content;
				left: -1px;
				width: calc(100% + 2px);
				border-radius: inherit;
				background-color: $cgp_font_color;
			}
		}
	}

	// ##################################################

	.title--h2 {
		font-family: $font_primary;
		font-weight: 600;
		text-transform: none;
		max-width: 832px;
		width: 100%;
		margin: 0 auto;
		min-height: 104px;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			min-height: 1px;
		}
	}

	// ##################################################

	&__answers {
		display: grid;
		grid-template-columns: repeat(3, minmax(0, 1fr));
		grid-gap: 15px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			grid-gap: 10px;
		}

		// ##################################################

		&-item {
			position: relative;
			border-radius: 10px;
			height: 0;
			padding-bottom: 83%;
			cursor: pointer;

			// Mobile
			@media #{$breakpoint-xs-only} {
				border-radius: 5px;
			}

			// ##################################################
			// Border

			&::after {
				@extend %content;
				border: 3px solid $cgp_font_color;
				border-radius: inherit;
				opacity: 0;

				// Mobile
				@media #{$breakpoint-xs-only} {
					border-width: 2px;
				}
			}

			// ##################################################

			// stylelint-disable-next-line
			&.#{$class_selected},
			&:hover {
				// stylelint-disable-next-line
				&::after {
					opacity: 1;
				}
			}
		}
	}
}
