// **************************************************
// Button
// **************************************************

@import '../../../scss/import';

// Style
.btn {
	position: relative;
	cursor: pointer;
	font-size: 28px;
	font-weight: 700;
	line-height: normal;
	padding: 2px 25px;
	min-width: 330px;
	min-height: 82px;
	border-radius: 10px;
	color: $cgp_font_color;
	font-family: $font_secondary;
	border: 4px solid $cgp_font_color;
	background-color: $cgp_white;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-transform: uppercase;
	text-align: center;
	transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 24px;
		min-height: 60px;
		min-width: 200px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		min-height: 50px;
		min-width: 150px;
		font-size: 20px;
		border-width: 3px;
	}

	// ##################################################

	i,
	span {
		position: relative;
		z-index: 4;
	}

	// ##################################################

	&:focus-visible {
		outline-color: transparent;
	}

	// ##################################################

	&__secondary-text {
		width: 100%;
		font-size: 22px;
		font-family: $font_primary;
		font-weight: 300;
		text-transform: none;
		margin-top: 6px;
		transition: color 0.3s ease;

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			font-size: 16px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			font-size: 15px;
		}
	}

	// ##################################################

	&--secondary {
		// Tablet L and Up
		@media #{$breakpoint-md-up} {
			padding: 32px 26px;
			min-height: 150px;
			min-width: 400px;
		}

		// Tablet P and down
		@media #{$breakpoint-sm-down} {
			padding: 20px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 10px 15px;
		}
	}

	// ##################################################
	// Hover/Focus

	&:hover,
	&:focus {
		color: $cgp_white;
		background-color: $cgp_font_color;
		box-shadow: 0 0 0 3px rgba($cgp_font_color, 0.2);

		// ##################################################

		.btn__secondary-text {
			color: $cgp_white;
		}
	}
}
