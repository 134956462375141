// ##################################################
// Grids
// ##################################################

$css_grid: false !default; // Use CSS Grid (true) or Flexbox (false)
$base-font-size: 16px !default;

// ##################################################
// Main Fonts
// ##################################################

$font_primary:					'Camel Sans', sans-serif;
$font_secondary:				'Camel', sans-serif;
$font_helvetica:				'Helvetica Neue', sans-serif;

// ##################################################
// Colors
// ##################################################

// NEUTRAL COLORS
$cgp_white:									#fff;
$cgp_black:									#000;
$cgp_font_color:							#07263d;
$cgp_gray:									#707070;
$cgp_validation_failed:						#f62d27;

// ##################################################
// Z-index
// ##################################################

$zindex_loader:          						9999;
$zindex_modal:          						999;
$zindex_cookie_notice:  						998;
$zindex_header:        						    100;
$zindex_search_overlay:							 98;


// ##################################################
// 100vh Height
// ##################################################

$vh_100: calc(var(--vh, 1vh) * 100);


// ##################################################
// Class names (read from config JSON)
// ##################################################

// Usage: body.#{$class_open_menu} {};

$class_active:								"is-active";
$class_open:								"is-open";
$class_open_modal:							"is-open-modal";
$class_open_menu:							"is-open-menu";
$class_open_menu_dropdown:					"is-open-menu-dropdown";
$class_open_mega_menu:						"is-open-mega-menu";
$class_open_search:							"is-open-search";
$class_open_submenu:						"is-open-submenu";
$class_selected:							"is-selected";
$class_scrolled:							"is-scrolled";
$class_scrolled_up:							"is-scrolled-up";
$class_focused:								"is-focused";
$class_failed:								"is-failed";
$class_disabled:							"is-disabled";
$class_cookie_not_set:						"cookie-not-set";
$class_validation_failed:					"is-validation-failed";
$class_open_gallery_modal:    				"is-open-gallery-modal";
$class_is_loader:		    				"is-loaded";
$class_show_thankyou:	    				"show-thankyou";
$class_clicked:	    						"is-clicked";
$class_scaled:	    						"is-scaled";
$class_animate:	    						"is-animate";

// ##################################################
// Font weight
// ##################################################

$extra_light_weight:						100;
$light_weight: 								200;
$book_weight: 					    		300;
$medium_weight: 							500;
$semibold_weight: 							600;
$bold_weight: 								700;
$black_weight: 								900;


// ##################################################
// Breakpoints
// ##################################################

$mobile_xs:									375px;

// ##################################################
// Shadow
// ##################################################

$main_box_shadow:		0 0 15px rgba(0, 0, 0, 0.1);
$image_path: "../images";

// ##################################################
// Spacings
// ##################################################

// 240px
$space240_lg: 240px;
$space240_md: 160px;
$space240_sm: 120px;
$space240_xs: 80px;

// 200px
$space200_lg: 200px;
$space200_md: 140px;
$space200_sm: 100px;
$space200_xs: 80px;

// 160px
$space160_lg: 160px;
$space160_md: 100px;
$space160_sm: 80px;
$space160_xs: 40px;

// 120px
$space120_lg: 120px;
$space120_md: 80px;
$space120_sm: 60px;
$space120_xs: 40px;

// 100px
$space100_lg: 100px;
$space100_md: 60px;
$space100_sm: 40px;
$space100_xs: 32px;

// 80px
$space80_lg: 80px;
$space80_sm: 40px;
$space80_xs: 32px;

// 70px
$space70_lg: 70px;
$space70_sm: 40px;
$space70_xs: 32px;

// 60px
$space60_lg: 60px;
$space60_sm: 32px;
$space60_xs: 24px;

// 40px
$space40_lg: 40px;
$space40_xs: 24px;

// 32px
$space32_lg: 32px;
$space32_xs: 24px;

// 24px
$space24_lg: 24px;
$space24_xs: 20px;

// 20px
$space20_lg: 20px;

// 16px
$space16_lg: 16px;

// 12px
$space12_lg: 12px;

// 8px
$space8_lg: 8px;
