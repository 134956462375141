//**********************************************************
// Game Screen
//**********************************************************

@import '../../../scss/import';

.game-screen {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: $vh_100;
	width: 100%;
	align-items: flex-start;
	text-align: center;

	// ##################################################

	&__body {
		max-width: 952px;
		width: 100%;
		flex: 1 0 auto;
		display: flex;
		flex-direction: column;
		margin: 0 auto;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			padding: 0 50px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding: 0 40px;
		}
	}

	// ##################################################

	&__link {
		position: relative;
		max-width: 850px;
		width: 100%;
		margin: 140px auto 0;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			margin-top: 90px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 50px;
		}

		// ##################################################

		.icon {
			position: absolute;
			top: 124%;
			left: 54%;
			pointer-events: none;
			z-index: 4;

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				top: 100%;
			}
		}
	}

	// ##################################################

	&__button-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 175px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			margin-top: 90px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 50px;
			flex-direction: column;
		}

		.btn {
			// Mobile
			@media #{$breakpoint-xs-only} {
				width: 100%;
			}
		}

		// ##################################################

		&-item {
			position: relative;

			// ##################################################

			&:not(:last-child) {
				margin-right: 50px;

				// Tablet P
				@media #{$breakpoint-sm-only} {
					margin-right: 25px;
				}

				// Mobile
				@media #{$breakpoint-xs-only} {
					margin: 0 0 40px;
				}
			}

			// ##################################################

			.icon {
				position: absolute;
				top: 112%;
				left: 72%;
				z-index: 4;
				pointer-events: none;

				// Tablet P and Down
				@media #{$breakpoint-sm-down} {
					top: 105%;
				}
			}

			// ##################################################

			&:last-child {
				.icon {
					left: 50%;
				}
			}
		}
	}

	// ##################################################

	.quiz-block {
		max-width: 100%;
	}

	// ##################################################

	&__headline {
		margin-bottom: 126px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			margin-bottom: 90px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 50px;
		}

		// ##################################################

		.editor-text {
			margin-top: 60px;

			// Tablet P
			@media #{$breakpoint-sm-only} {
				margin-top: 30px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				margin-top: 20px;
			}
		}
	}
}
