//**********************************************************
// Sample List
//**********************************************************

@import '../../../scss/import';

.sample-list {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-gap: 100px;
	max-width: 860px;
	margin: 0 auto;
	text-align: center;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		grid-gap: 60px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		grid-gap: 30px;
	}

	// ##################################################

	.title {
		@extend %fontsize30;
		text-transform: none;
		margin-bottom: 18px;
	}

	// ##################################################

	&__item {
		@extend %bg_shadow;
		position: relative;
	}

	// ##################################################

	&__link {
		position: absolute;
		width: 100%;
		height: 30%;
		left: 0;
		top: 0;
		z-index: 4;
		font-size: 0;
		line-height: 0;

		// ##################################################
		// Scale Clickable Area

		&::before {
			@extend %content;
			pointer-events: all;
			transform: scale(1.2);
		}
	}
}
