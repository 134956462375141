//**********************************************************
// Info Block
//**********************************************************

@import '../../../scss/import';

.info-block {
	max-width: 910px;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	overflow: hidden;

	// ##################################################

	.title {
		@extend %fontsize50;
		margin-bottom: 42px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			margin-bottom: 30px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-bottom: 20px;
		}
	}

	// ##################################################

	&__image {
		@extend %bg_shadow;
		position: relative;
		max-width: 300px;
		width: 100%;
		margin: 86px auto 0;
		transform: rotate(10deg);
		transform-origin: 18% 20%;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			max-width: 250px;
			margin-top: 50px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 200px;
		}

		// Mobile xs
		@media (max-width: $mobile_xs) {
			max-width: 160px;
		}

		// ##################################################
		// Shadow

		&::before {
			top: -10%;
			left: -60%;
			height: 136%;
			width: 242%;
			transform-origin: 18% 20%;
		}

		// ##################################################

		img {
			object-fit: contain;
		}
	}

	// ##################################################

	&__footer {
		margin-top: 116px;

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 96px;
		}
	}
}
