//**********************************************************
// About Block
//**********************************************************

@import '../../../scss/import';

.about-block {
	max-width: 832px;
	width: 100%;
	margin: 0 auto;
	text-align: center;

	// ##################################################

	&__step {
		display: none;

		// stylelint-disable-next-line
		&.#{$class_active} {
			display: block;
		}
	}

	// ##################################################

	&__title {
		@extend %title_h1;
		font-family: $font_primary;
		font-weight: 600;
		text-transform: none;
		position: relative;
		z-index: 4;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			font-size: 30px;
		}
	}

	// ##################################################

	&__image {
		@extend %bg_shadow;
		position: relative;
		max-width: 278px;
		width: 100%;
		margin: 46px auto 0;
		z-index: 5;
		transform: rotate(10deg);
		transition: transform 0.3s ease;

		// stylelint-disable-next-line
		&.#{$class_scaled} {
			transform: scale(1.02) rotate(10deg);
		}

		// Tablet P
		@media #{$breakpoint-sm-only} {
			max-width: 180px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 150px;
		}

		// ##################################################
		// Shadow

		&::before {
			top: 18%;
			left: -36%;
			height: 103%;
			width: 206%;
			z-index: -1;
		}

		// ##################################################

		img {
			object-fit: contain;
			position: relative;
			z-index: 4;
		}

		// ##################################################

		&-top-image {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 5;
			pointer-events: none;
		}
	}

	// ##################################################

	.camel-svg {
		position: absolute;
		width: 0;
		height: 0;
	}

	// ##################################################

	&__camel-shine {
		position: absolute;
		width: 101px;
		height: 89px;
		bottom: 3%;
		left: 31.4%;
		opacity: 1;
		z-index: 6;
		overflow: hidden;
		background-size: cover;
		-webkit-clip-path: url(#camel-svg-clip-path);
		clip-path: url(#camel-svg-clip-path);

		// Tablet P
		@media #{$breakpoint-sm-only} {
			width: 66px;
			height: 58px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 56px;
			height: 48px;
		}

		// ##################################################
		// Shine

		&::after {
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
			left: -100%;
			transition: all 0.65s ease-in-out;

			// stylelint-disable-next-line
			.#{$class_clicked} & {
				left: 100%;
			}
		}
	}

	// ##################################################

	&__leaves {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		transform: rotate(-10deg);
		z-index: 1;

		// ##################################################

		&-item {
			position: absolute;
			top: 0;
			opacity: 0;
			transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

			// Tablet P
			@media #{$breakpoint-sm-down} {
				transform: scale(0.8);
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				transform: scale(0.5);
			}

			// stylelint-disable-next-line
			.#{$class_clicked} & {
				opacity: 1;
				transform: translate(0, 0);

				// Tablet P
				@media #{$breakpoint-sm-down} {
					transform: translate(0, 0) scale(0.8);
				}

				// Mobile
				@media #{$breakpoint-xs-only} {
					transform: translate(0, 0) scale(0.5);
				}
			}

			// ##################################################

			$i: 1;
			@for $i from 1 through 6 {
				// stylelint-disable-next-line
				&:nth-child(#{$i}) {
					transition-delay: #{$i * 100}ms;
				}
				$i: $i + 1;
			}

			// ##################################################

			&--1 {
				top: -18%;
				left: -165%;
				transform: translate(180%, 230%);
			}

			// ##################################################

			&--2 {
				top: -16%;
				left: -110%;
				transform: translate(150%, 140%);
			}

			// ##################################################

			&--3 {
				top: 6%;
				left: -150%;
				transform: translate(140%, 170%);
			}

			// ##################################################

			&--4 {
				top: -6%;
				left: -62%;
				transform: translate(170%, 180%);
			}

			// ##################################################

			&--5 {
				top: 25%;
				left: -95%;
				transform: translate(150%, 150%);
			}

			// ##################################################

			&--6 {
				top: 20%;
				left: -47%;
				transform: translate(100%, 100%);
			}

			// ##################################################

			&--7 {
				top: -12%;
				right: -182%;
				transform: translate(-210%, 270%);
			}

			// ##################################################

			&--8 {
				top: -9%;
				right: -127%;
				transform: translate(-190%, 150%);
			}

			// ##################################################

			&--9 {
				top: 12%;
				right: -167%;
				transform: translate(-180%, 250%);
			}

			// ##################################################

			&--10 {
				top: -4%;
				right: -75%;
				transform: translate(-200%, 210%);
			}

			// ##################################################

			&--11 {
				top: 31%;
				right: -110%;
				transform: translate(-200%, 100%);
			}

			// ##################################################

			&--12 {
				top: 26%;
				right: -63%;
				transform: translate(-140%, 100%);
			}
		}
	}

	// ##################################################

	&__circle {
		height: 42px;
		width: 42px;
		border-radius: 100%;
		position: absolute;
		z-index: 7;
		opacity: 0;
		pointer-events: none;
		transition: transform 0.3s ease, opacity 1.1s ease;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			height: 36px;
			width: 36px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			height: 20px;
			width: 20px;
			border-width: 2px;
		}

		// ##################################################
		//  Circle Border

		&::before {
			@extend %content;
			border: 3px solid $cgp_font_color;
			border-radius: 100%;
		}

		// ##################################################
		// Inner Circle

		&::after {
			content: '';
			@include horizontal-vertical-align(absolute);
			height: 22px;
			width: 22px;
			background-color: $cgp_font_color;
			opacity: 0.3;
			border-radius: 100%;
			transition: opacity 0.3s ease;

			// Tablet P and Down
			@media #{$breakpoint-sm-down} {
				height: 18px;
				width: 18px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				height: 10px;
				width: 10px;
			}
		}

		// ##################################################

		// stylelint-disable-next-line
		&.#{$class_clicked},
		&:hover {
			transform: scale(1.1);

			// stylelint-disable-next-line
			&::after {
				opacity: 1;
			}
		}

		// stylelint-disable-next-line
		&.#{$class_animate} {
			&::before {
				-webkit-animation: pulsate 1s ease-out;
				-webkit-animation-iteration-count: infinite;
				opacity: 0;
			}
		}

		// stylelint-disable-next-line
		.#{$class_is_loader} & {
			opacity: 1;
			pointer-events: all;
		}
	}

	// ##################################################

	&__image-list {
		max-width: 324px;
		width: 100%;
		display: grid;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-gap: 60px;
		margin-top: 164px;
		margin-left: -16px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			margin-top: 100px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 80px;
			max-width: 230px;
			grid-gap: 40px;
		}

		// ##################################################

		&-item {
			@extend %bg_shadow;
			position: relative;
		}
	}

	// ##################################################

	&__cigarette-image {
		position: absolute;
		width: 80px;
		height: 248px;
		top: 26.6%;
		left: 36%;
		opacity: 1;
		pointer-events: none;
		transition: top 0.3s ease;

		// Tablet P
		@media #{$breakpoint-sm-down} {
			width: 75px;
			height: 162px;
			top: 26%;
			left: 29%;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			width: 25px;
			height: 142px;
			left: 42%;
		}

		// stylelint-disable-next-line
		.#{$class_is_loader} & {
			opacity: 1;
			top: 0;
		}

		// ##################################################

		img {
			object-fit: contain;
		}
	}

	// ##################################################

	.sample-list {
		margin-top: 188px;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			margin-top: 80px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			margin-top: 60px;
		}
	}
}
