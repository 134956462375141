//*******************************************************************************
// 1.0 WEBFONTS : Camel
//*******************************************************************************

@font-face {
	font-family: 'Camel';
	src: local('Camel Bold'), local('Camel-Bold'), url('../fonts/Camel-Bold.woff2') format('woff2'),
		url('../fonts/Camel-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

//*******************************************************************************
// 2.0 WEBFONTS : Camel Sans
//*******************************************************************************

@font-face {
	font-family: 'Camel Sans';
	src: local('Camel Sans Light'), local('CamelSans-Light'), url('../fonts/CamelSans-Light.woff2') format('woff2'),
		url('../fonts/CamelSans-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Camel Sans';
	src: local('Camel Sans Demi'), local('CamelSans-Demi'), url('../fonts/CamelSans-Demi.woff2') format('woff2'),
		url('../fonts/CamelSans-Demi.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

//*******************************************************************************
// 3.0 WEBFONTS : Helvetica Neue
//*******************************************************************************

@font-face {
	font-family: 'Helvetica Neue';
	src: local('Helvetica Neue Bold'), local('HelveticaNeue-Bold'), url('../fonts/HelveticaNeue-Bold.woff2') format('woff2'),
		url('../fonts/HelveticaNeue-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}
