//*******************************************************************************
// Animations
//*******************************************************************************

.floating {
	-webkit-animation: foating 10s ease infinite;
	animation: foating 10s ease infinite;
}

@keyframes foating {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(30px);
	}
	100% {
		transform: translateY(0);
	}
}

// ##################################################
// shine

@keyframes shine {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}

// ##################################################
// Pulsate

@-webkit-keyframes pulsate {
	0% {
		-webkit-transform: scale(0.5, 0.5);
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1.2, 1.2);
		opacity: 0;
	}
}

// ##################################################

@keyframes swipe {
	0% {
		transform: translate(-100%) rotate(-15deg);
	}

	50% {
		transform: translate(140%) rotate(15deg);
	}

	100% {
		transform: translate(-100%) rotate(-15deg);
	}
}
