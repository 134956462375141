//**********************************************************
// Section Footer
//**********************************************************

@import '../../../scss/import';

.section-footer {
	max-width: 1024px;
	width: 100%;
	margin: 200px auto 0;
	padding: 0 18px 40px;
	text-align: center;

	// Tablet P and Down
	@media #{$breakpoint-sm-down} {
		margin-top: 100px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 0 20px 20px;
		margin-top: 60px;
	}

	// ##################################################

	&__text {
		padding-top: 42px;
		border-top: 2px solid $cgp_gray;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			padding-top: 30px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			padding-top: 20px;
		}
	}

	// ##################################################

	&__image {
		max-width: 240px;
		width: 100%;
		margin: 0 14px 54px auto;

		// Tablet P and Down
		@media #{$breakpoint-sm-down} {
			max-width: 120px;
			margin-right: 0;
			margin-bottom: 38px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 90px;
			margin-bottom: 30px;
		}

		// ##################################################

		img {
			object-fit: contain;
		}
	}
}
