//**********************************************************
// Section Header
//**********************************************************

@import '../../../scss/import';

.section-header {
	position: relative;
	width: 100%;
	padding: 158px 0;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		padding: 112px 0 80px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		padding: 85px 0 40px;
	}

	// ##################################################

	&__image {
		position: absolute;
		top: -270px;
		left: -357px;
		width: 880px;
		height: 1060px;
		pointer-events: none;
		z-index: -1;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			top: -90px;
			left: -237px;
			width: 640px;
			height: 600px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			top: -130px;
			left: -177px;
			width: 430px;
			height: 500px;
		}

		// ##################################################

		img {
			object-fit: contain;
			mix-blend-mode: multiply;
		}

		// ##################################################

		&--right {
			left: inherit;
			right: -452px;
			top: -125px;
			width: 1062px;
			height: 798px;

			// Tablet P
			@media #{$breakpoint-sm-only} {
				right: -412px;
				top: -125px;
				width: 822px;
				height: 528px;
			}

			// Mobile
			@media #{$breakpoint-xs-only} {
				right: -322px;
				top: -125px;
				width: 632px;
				height: 378px;
			}
		}
	}

	// ##################################################

	&__logo {
		max-width: 528px;
		width: 100%;
		display: block;
		margin: 0 auto;
		position: relative;
		z-index: 4;

		// Tablet P
		@media #{$breakpoint-sm-only} {
			max-width: 280px;
		}

		// Mobile
		@media #{$breakpoint-xs-only} {
			max-width: 200px;
		}

		// ##################################################

		img {
			object-fit: contain;
		}
	}
}
