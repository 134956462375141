//*******************************************************************************
// PLACEHOLDERS
//*******************************************************************************

// General extensions
%content {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	pointer-events: none;
}

// ##################################################

%reset_button {
	background: transparent;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

// ##################################################

%abs_pos_fullwidth {
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
	top: 0;
	pointer-events: none;
}

// ##################################################

%flex_center_center {
	display: flex;
	align-items: center;
	justify-content: center;
}

// ##################################################

%sr_only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	white-space: nowrap;
	border: 0;
	visibility: inherit;
	clip: rect(0, 0, 0, 0);
}

// ##################################################
// FONT SIZES
// ##################################################

// General page text - Paragraph
%page_text {
	font-size: 36px;
	font-weight: 600;
	line-height: 1.39;

	// Tablet P and down
	@media #{$breakpoint-sm-down} {
		font-size: 20px;
	}
}

// ##################################################

%title {
	font-weight: 700;
	font-family: $font_secondary;
	text-transform: uppercase;
}

// ##################################################

%title_h1 {
	@extend %title;
	font-size: 46px;
	line-height: 1.3;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 36px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 22px;
	}
}

// ##################################################

%title_h2 {
	@extend %title;
	font-size: 40px;
	font-weight: 600;
	line-height: normal;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 30px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 20px;
	}
}

// ##################################################

%title_h3 {
	@extend %title;
	font-size: 36px;
	font-weight: 600;
	line-height: 1.39;

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 28px;
	}
}

// ##################################################

%title_h4 {
	@extend %title;
	font-size: 28px;
	line-height: normal;
}

// ##################################################

%title_h5 {
	@extend %title;
	font-size: 22px;
	line-height: normal;
}

// ##################################################

%title_h6 {
	@extend %title;
	font-size: 16px;
	line-height: 1.44;
}

// ##################################################

%fontsize50 {
	font-size: 50px;
	line-height: normal;
	font-weight: 700;
	font-family: $font_secondary;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 30px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 20px;
	}
}

// ##################################################

%fontsize45 {
	font-size: 45px;
	line-height: 1.2;
	font-weight: 700;
	font-family: $font_helvetica;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 30px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%fontsize44 {
	font-family: $font_secondary;
	font-size: 44px;
	line-height: 1.48;
	font-weight: 700;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 26px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%fontsize30 {
	font-family: $font_primary;
	font-size: 30px;
	line-height: 1.33;
	font-weight: 600;

	// Tablet P
	@media #{$breakpoint-sm-only} {
		font-size: 22px;
	}

	// Mobile
	@media #{$breakpoint-xs-only} {
		font-size: 16px;
	}
}

// ##################################################

%inherit_textstyles {
	color: inherit;
	font-size: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	text-transform: inherit;
	text-align: inherit;
	font-style: inherit;
	font-family: inherit;
	background-color: transparent;
}

// ##################################################

%backface {
	overflow: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
}

// ##################################################

%focus_visible {
	outline-offset: 0;
	outline: 2.5px solid $cgp_black;
}

// ##################################################

%bg_video {
	position: absolute;
	min-width: 100%;
	min-height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: $cgp_black;
}

// ##################################################

%bg_shadow {
	&::before {
		content: '';
		position: absolute;
		top: -10%;
		left: -28%;
		height: 138%;
		width: 176%;
		z-index: -1;
		background: url(#{$image_path}/shadow.png) no-repeat center / contain;
	}
}
